<template>
  <ErrorView v-if="error || !pending && !isCategoryCorrect" is-page/>
  <main v-else>
    <MainCarousel v-if="$get(settings, 'slider_top')"
                  :slides="topBanner"
                  :pending="pendingTopBanner"
    />
    <div class="events-list-page container">
      <div class="events-list-page__inner container-inner">
        <EventsListMenu :categories="categories"
                        :pendingCategories="pendingCategories"
                        class="events-list-page__menu"/>
        <EventsList ref="list"
                    :event-items="eventItems"
                    :loaded-pages="loadedPages"
                    :total-pages="totalPages"
                    :pending="pending"
                    :pending-more="pendingMore"
                    @more="onLoadMore"
                    class="events-list-page__list"/>
      </div>
    </div>
  </main>
</template>

<script>
  import EventsListMenu from '../components/EventsListMenu'
  import EventsList from '../components/EventsList'
  import {http} from '@/axios'
  import MainCarousel from '@/components/MainCarousel'
  import ErrorView from '@/components/ErrorView'
  import {mapState} from 'vuex'

  export default {
    name: 'News',
    components: {
      ErrorView,
      MainCarousel,
      EventsListMenu,
      EventsList
    },
    data() {
      return {
        categories: null,
        pendingCategories: true,
        topBanner: [],
        pendingTopBanner: true,
        eventItems: [],
        loadedPages: [],
        totalPages: 1,
        pending: true,
        pendingMore: false,
        error: null
      }
    },
    computed: {
      ...mapState('settings', ['settings']),
      categoryId() {
        return this.$route.params.categoryId || null
      },
      isCategoryCorrect() {
        return this.categories && this.categories.some(item => {
          return !!this.categoryId && item.slug === this.categoryId
        })
      },
      currentPage() {
        return parseFloat(this.$route.query.page) || 1
      },
    },
    methods: {
      loadCategories() {
        this.error = null
        this.pendingCategories = true

        http.get('/api/news/categories')
            .then(({data}) => {
              this.categories = data
              if (!this.$route.params.categoryId) {
                this.$router.replace({
                  name: 'events-list',
                  params: {categoryId: data[0].slug},
                })
              }
              this.$nextTick(() => {
                this.loadNews()
              })
            })
            .catch((error) => {
              this.error = error
            })
            .finally(() => {
              this.pendingCategories = false
            })
      },
      loadTopBanner() {
        if (!this.$get(this.settings, 'slider_top', false)) {
          this.pendingTopBanner = false
          return
        }
        this.pendingTopBanner = true
        this.$store.dispatch('banners/load').then((data) => {
          this.topBanner = data.top
        }).finally(() => {
          this.pendingTopBanner = false
        })
      },
      loadNews() {
        this.error = null
        const isMore = !!this.$route.params.loadMore
        if (isMore) {
          this.pendingMore = true
        } else {
          this.pending = true
        }

        if (!this.isCategoryCorrect) {
          this.eventItems = []
          this.pending = false
          this.pendingMore = false
          return
        }
        http.get('/api/news/list', {
          params: {
            category: this.categoryId,
            page: this.currentPage,
            limit: 10
          },
        })
            .then(({data}) => {
              if (isMore) {
                this.eventItems = this.eventItems.concat(data.data)
                this.loadedPages.push(this.currentPage)
              } else {
                this.eventItems = data.data
                this.loadedPages = [this.currentPage]
              }

              this.totalPages = data.meta.last_page
            })
            .catch((error) => {
              this.error = error
            })
            .finally(() => {
              this.pending = false
              this.pendingMore = false
            })
      },
      onLoadMore() {
        const newPage = this.currentPage + 1
        this.$router.push({
          query: {page: newPage},
          params: {loadMore: true, savePosition: true},
        })
      },
    },
    created() {
      this.loadCategories()
      this.loadTopBanner()
    },
    watch: {
      categoryId() {
        if (!this.$route.params.categoryId && this.categories && this.categories.length) {
          this.$router.replace({
            name: 'events-list',
            params: {categoryId: this.categories[0].slug},
          })
        } else {
          this.loadNews()
        }
      },
      currentPage() {
        this.loadNews()
      }
    }
  }
</script>
