<template>
  <ErrorView v-if="error" is-page/>
  <main v-else class="event-page"
        :class="{_loading: pending}">
    <BasePreloader v-if="pending"/>
    <template v-else>
      <EventPageContent
          :event="event"
          class="event-page__content"
      />
      <LastNews :title="$t('Останні новини')"
                :pending="pendingLastNews"
                :last-news="lastNews"
      />
    </template>
  </main>
</template>

<script>
  import {http} from '@/axios'
  import EventPageContent from '../components/EventPageContent'
  import LastNews from '../components/LastNews'
  import ErrorView from '@/components/ErrorView'

  export default {
    components: {
      ErrorView,
      LastNews,
      EventPageContent
    },
    data() {
      return {
        pending: true,
        event: {},
        pendingLastNews: true,
        lastNews: [],
        error: null
      }
    },
    methods: {
      load() {
        this.pending = true
        this.error = null

        const idNews = this.$route.params.id

        http.get(`/api/news/${idNews}`).then(({data}) => {
          this.event = data.item
        }).catch((error) => {
          this.error = error
        }).finally(() => {
          this.pending = false
        })
        this.loadLastNews()
      },
      loadLastNews() {
        this.pendingLastNews = true
        http.get('/api/news/list', {
          params: {
            page: 1,
            limit: 10
          }
        }).then(({data}) => {
          this.lastNews = data.data.filter(item => item.slug !== this.$route.params.id)
        }).finally(() => {
          this.pendingLastNews = false
        })
      },
    },
    created() {
      this.load()
    },
    watch: {
      '$route.params.id'() {
        this.pending = true
        this.event = {}
        this.lastNews = []
        this.pendingLastNews = true
        this.error = null
        window.scrollTo({
          top: 0
        })
        this.load()
      }
    }
  }
</script>
