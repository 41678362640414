<template>
  <div class="events-list-menu" :class="{'_loading':pendingCategories}">
    <BasePreloader v-if="pendingCategories"/>
    <div class="events-list-menu__inner">
      <router-link
          v-for="(item, index) in categories"
          :key="index"
          :to="{name: 'events-list', params: {categoryId: item.slug}}"
          class="events-list-menu__link"
      >
        <span>{{ item.name }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>

  export default {
    props: {
      categories: null,
      pendingCategories: Boolean
    }
  }
</script>
