<template>
  <div class="events-list" :class="{'_loading': pending}">
    <BasePreloader v-if="pending"/>
    <p v-if="!pending && !eventItems.length"
       class="events-list__not-found">
      {{ $t('На даний момент новини в розділі відсутні') }}
    </p>
    <div v-if="eventItems.length" class="events-list__list">
      <EventCard
          v-for="event in eventItems"
          :key="event.id"
          :event="event"
          class="events-list__item"
      ></EventCard>
    </div>
    <Pagination
        v-if="totalPages > 1"
        :totalPages="totalPages"
        :activePages="loadedPages"
        withMore
        :pendingMore="pendingMore"
        @more="$emit('more')"
        class="events-list__pagination"
    />
  </div>
</template>

<script>
  import EventCard from './EventCard'
  import Pagination from './Pagination'

  export default {
    name: 'NewsList',
    components: {
      EventCard,
      Pagination,
    },
    props: {
      pendingCategories: Boolean,
      isCategoryCorrect: Boolean,
      eventItems: null,
      loadedPages: null,
      totalPages: null,
      pending: Boolean,
      pendingMore: Boolean
    }
  }
</script>
