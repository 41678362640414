<template>
  <div class="event-page-content  container">
    <div class="event-page-content__inner container-inner">
      <div class="event-page-content__img-wrap-outer">
        <picture class="event-page-content__img-wrap">
          <source :srcset="$imgPlaceholder"
                  :data-srcset="event.image_m"
                  media="(min-width: 730px)">
          <source :srcset="$imgPlaceholder"
                  :data-srcset="event.image_s"
                  media="(min-width: 470px)">
          <img :src="$imgPlaceholder"
               :data-src="event.image_s"
               :alt="event.title"
               class="event-page-content__img lazyload">
        </picture>
        <time :datetime="event.date | formatDateForTimeTag"
              class="event-page-content__date">{{ event.date | formatDate }}
        </time>
        <div class="event-page-content__views views-element">
          <BaseSvg name="ico-eye"/>
          <span>{{ event.views }}</span>
        </div>
      </div>

      <h1 class="event-page-content__title">
        {{ event.name }}
      </h1>
      <div v-if="event.tags && event.tags.length"
           class="event-page-content__tags  tags">
        <div class="tags__list">
          <div v-for="(item, index) in event.tags"
               :key="index"
               class="tags__tag">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div v-html="event.description" class="event-page-content__text text"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    event: null,
    pending: Boolean
  }
};
</script>
